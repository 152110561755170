@font-face {
  font-family: '나눔바른고딕';
  src: url(./NanumBarunGothic.eot);
  src: local('나눔바른고딕 '),
       local('나눔바른고딕'),
       url(./NanumBarunGothic.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothic.woff2) format('woff2'),
       url(./NanumBarunGothic.woff) format('woff'),
       url(./NanumBarunGothic.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: 'nanum-barun-gothic';
  src: url(./NanumBarunGothic.eot);
  src: local('nanum-barun-gothic '),
       local('nanum-barun-gothic'),
       url(./NanumBarunGothic.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothic.woff2) format('woff2'),
       url(./NanumBarunGothic.woff) format('woff'),
       url(./NanumBarunGothic.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: '나눔바른고딕';
  src: url(./NanumBarunGothicBold.eot);
  src: local('나눔바른고딕 '),
       local('나눔바른고딕'),
       url(./NanumBarunGothicBold.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicBold.woff2) format('woff2'),
       url(./NanumBarunGothicBold.woff) format('woff'),
       url(./NanumBarunGothicBold.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: 'nanum-barun-gothic';
  src: url(./NanumBarunGothicBold.eot);
  src: local('nanum-barun-gothic '),
       local('nanum-barun-gothic'),
       url(./NanumBarunGothicBold.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicBold.woff2) format('woff2'),
       url(./NanumBarunGothicBold.woff) format('woff'),
       url(./NanumBarunGothicBold.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: '나눔바른고딕';
  src: url(./NanumBarunGothicLight.eot);
  src: local('나눔바른고딕 '),
       local('나눔바른고딕'),
       url(./NanumBarunGothicLight.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicLight.woff2) format('woff2'),
       url(./NanumBarunGothicLight.woff) format('woff'),
       url(./NanumBarunGothicLight.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: 'nanum-barun-gothic';
  src: url(./NanumBarunGothicLight.eot);
  src: local('nanum-barun-gothic '),
       local('nanum-barun-gothic'),
       url(./NanumBarunGothicLight.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicLight.woff2) format('woff2'),
       url(./NanumBarunGothicLight.woff) format('woff'),
       url(./NanumBarunGothicLight.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: '나눔바른고딕';
  src: url(./NanumBarunGothicUltraLight.eot);
  src: local('나눔바른고딕 '),
       local('나눔바른고딕'),
       url(./NanumBarunGothicUltraLight.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicUltraLight.woff2) format('woff2'),
       url(./NanumBarunGothicUltraLight.woff) format('woff'),
       url(./NanumBarunGothicUltraLight.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}
@font-face {
  font-family: 'nanum-barun-gothic';
  src: url(./NanumBarunGothicUltraLight.eot);
  src: local('nanum-barun-gothic '),
       local('nanum-barun-gothic'),
       url(./NanumBarunGothicUltraLight.eot?#iefix) format('embedded-opentype'),
       url(./NanumBarunGothicUltraLight.woff2) format('woff2'),
       url(./NanumBarunGothicUltraLight.woff) format('woff'),
       url(./NanumBarunGothicUltraLight.ttf) format('truetype');
  font-display: swap;
  font-style: normal;
  font-weight: 100;
}
